<template>
  <header :ref="'header' + id" :class="{ 'column-on-mobile': ifSticky }">
    <div class="header-image">
      <ImageCarousel
        :assets="getImgCdnUrls(operator.thumbnails)"
        :alt="operator?.name" />
    </div>

    <div class="header">
      <div class="details">
        <h1 class="operator-name">{{ operator.name }}</h1>

        <template v-if="operator.address">
          <Address
            v-if="mobileLayout && !showFullAddress"
            :address="operator.address"
            @click="showFullAddress = !showFullAddress" />
          <Address
            v-if="!mobileLayout"
            :address="operator.address"
            if-fullAddress
            :showToggle="showMaxMenuSection && showFullAddress" />
        </template>
      </div>
      <Address
        v-show="showFullAddress"
        if-fullAddress
        show-toggle
        :address="operator.address"
        @click="showFullAddress = false" />

      <!-- TODO: Add some kinda day picker  that updates which services you can see, not clear how to use space effectively  -->

      <div class="selector" v-if="showDatePicker">
        <template v-if="getDateOptions.length > 0">
          <SelectorDay
            v-for="option of getDateOptions"
            :key="option.id"
            :current="currentEvent.id === option.id"
            :option="option"
            @click="$emit('selectDate', option)" />
        </template>
        <NoDataText
          v-else
          minHeight="40px"
          width="fit-content"
          text="No Upcoming Services" />
      </div>
    </div>
  </header>
</template>

<script>
  import Enumerable from "linq";
  import { mdiMapMarker, mdiCalendar } from "@mdi/js";
  import NoDataText from "@/components/NoDataText";
  import ImageCarousel from "@/components/Images/ImageCarousel.vue";
  import Address from "@/components/ListHeader/Address.vue";
  import SelectorDay from "@/components/ListHeader/SelectorDay.vue";

  export default {
    data() {
      return {
        mdiMapMarker,
        mdiCalendar,
        showFullAddress: false,
        scrollPosition: 0,
        showDatePicker: true,
        showMaxMenuSection: false,
        datePickerTimestamp: Date.now(),
        headerHeight: 0,
        id: null,
      };
    },
    props: {
      mobileLayout: { type: Boolean },
      tabletLayout: { type: Boolean },
      operator: { type: Object },
      siteName: { type: String },
      siteAddress: { type: String },
      events: { type: Array },
      currentEvent: { type: Object },
      ifSticky: { type: Boolean }, // only if sticky style needs changing
    },
    components: {
      NoDataText,
      ImageCarousel,
      Address,
      SelectorDay,
    },
    computed: {
      getDateOptions() {
        return Enumerable.from(this.events)
          .orderBy((x) => x.date)
          .toArray();
      },

      getCurrentDate() {
        return this.formatDate(this.currentEvent?.date, "dd/MM");
      },
    },
    emits: ["selectDate"],
    watch: {
      scrollPosition() {
        if (!this.ifSticky) return;

        const header = this.$refs["header" + this.id];

        this.datePickerTimestamp = Date.now();
        const ifHeaderOnTop = header.getBoundingClientRect().top < 100;
        if (ifHeaderOnTop) {
          header.classList.add("mini");
        } else {
          header.classList.remove("mini");
        }
        //   // Scrolling down after the header is hidden: Show fixed bar
        //   if (newValue >= this.headerHeight && newValue > preValue) {
        //     this.showMaxMenuSection = true;
        //     return;
        //   }

        //   // Scrolling up
        //   if (newValue < preValue && newValue <= this.headerHeight + 150) {
        //     this.showMaxMenuSection = false;
        //     this.showDatePicker = true;
        //     return;
        //   }
      },
    },
    methods: {
      getImgCdnUrls(thumbnails) {
        return thumbnails.map(
          ({ cdnUrl }) => `${process.env.VUE_APP_CDN}` + "/" + cdnUrl
        );
      },
      handelScroll() {
        if (Date.now() - this.datePickerTimestamp > 20)
          this.scrollPosition = Math.round(window.scrollY);
      },
      resetView() {
        this.headerHeight = 0;
        this.showMaxMenuSection = false;
        window.addEventListener("scroll", this.handelScroll);
      },
    },
    mounted() {
      this.resetView();
      this.id = Math.floor(Math.random() * 100);
      window.addEventListener("resize", this.resetView);
      const header = this.$refs["header" + this.id];
      if (header) this.headerHeight = header.clientHeight;
    },
    beforeUnmount() {
      window.removeEventListener("scroll", this.handelScroll);
      window.removeEventListener("resize", this.resetView);
    },
  };
</script>

<style lang="scss" scoped>
  header {
    @include outlet-list-header-container;

    .mobileLayout &.column-on-mobile {
      flex-direction: row !important;
    }
  }

  .header-image {
    width: 33%;
    z-index: 9;
    max-width: 250px;
    min-width: 150px;

    @include outlet-list-header-image;

    @media screen and (max-width: 600px) {
      max-width: unset;
      padding: 0;
    }

    .mobileLayout & {
      width: 100%;
      position: relative;
      top: unset;
      height: auto;
    }
  }

  .header {
    @include outlet-list-header;
  }

  .details {
    @include outlet-list-details;
  }

  .selector {
    @include outlet-list-selector;
  }

  .operator-name {
    @include view-main-title;

    .mini & {
      padding-right: 5%;
      font-size: 1rem;
    }
  }
</style>
