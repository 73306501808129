<template>
  <div class="venue-site">
    <SiteListHeader
      v-if="!loading && venue && Object.keys(getCurrentEvent).length > 1"
      :ifSticky="true"
      :operator="{ ...venue, name: venue.name }"
      :events="events"
      :currentEvent="currentEvent"
      :mobileLayout="mobileLayout"
      @selectDate="selectEvent" />

    <ListHeader
      v-if="!loading && venue && Object.keys(getCurrentEvent).length === 1"
      :ifSticky="true"
      :operator="{
        ...venue,
        name:
          Object.keys(getCurrentEvent)[0] == 'undefined'
            ? venue.name
            : Object.keys(getCurrentEvent)[0],
      }"
      :events="events"
      :currentEvent="currentEvent"
      :mobileLayout="mobileLayout"
      @selectDate="selectEvent" />

    <div v-if="loading" class="loading-spinner_container">
      <LoadingSpinner />
    </div>

    <section
      class="event-list"
      v-for="(services, siteName, i) in getCurrentEvent"
      :key="i">
      <div
        class="event-list-header"
        v-if="Object.keys(getCurrentEvent).length > 1">
        <h2>{{ siteName }}</h2>
        <Address
          v-if="services[0]?.pitch?.site?.address"
          :address="services[0].pitch.site.address"
          :ifFullAddress="true" />
      </div>
      <div v-if="showWarning">
        <EventWarnings :warning="warning" />
      </div>

      <div class="event" v-if="!loading && services != null">
        <div>
          <ul class="service-list">
            <li
              v-for="service in services"
              :key="service.id"
              class="service-list_item card"
              :class="{
                closed:
                  service.status == 'Finished' || service.status == 'Cancelled',
                carousel: getThumbnail(service.thumbnails).length > 0,
              }">
              <div
                v-if="
                  service.status == 'Finished' || service.status == 'Cancelled'
                "
                class="inactive flex-center">
                <h2>Closed</h2>
              </div>
              <router-link
                :to="'/service/' + service.id"
                class="service-list_link"
                :disabled="
                  service.channels.length == 1 &&
                  service.channels[0].name == 'WalkUp'
                ">
                <div class="image-container_outer">
                  <div
                    class="image-container_inner"
                    :class="{
                      empty: getThumbnail(service.thumbnails).length === 0,
                    }">
                    <ImageCarousel
                      :assets="getThumbnail(service.thumbnails)"
                      :alt="service.outlet.nameDisplay"
                      @click="this.$router.push('/service/' + service.id)" />
                  </div>
                </div>

                <div
                  class="service-list_link_content_outer"
                  @click="routerPush(service)">
                  <div
                    class="busyness-indicator_outer"
                    v-if="
                      availableTimes[service.id] != null &&
                      service.status != 'Finished'
                    ">
                    <div class="busyness-indicator_inner flex-center">
                      <Icon :path="mdiCellphoneLink" :size="15" />
                      <span
                        v-if="!serviceIsOpen[service.id]"
                        class="time-range">
                        Opens at {{ LocalTime.parse(service.startTime) }}
                      </span>
                      <span
                        class="time-range"
                        v-else-if="availableTimes[service.id] > 0"
                        >{{ availableTimes[service.id] }} min</span
                      >
                      <span class="time-range" v-else>No wait</span>
                    </div>
                  </div>

                  <div class="service-list_link_content_inner">
                    <h4 class="vendor-name">
                      {{ replace(service.name, service.outlet.nameDisplay) }}
                    </h4>

                    <p v-if="service.cuisines.length" class="cuisines">
                      <Icon
                        :path="mdiFood"
                        :size="16"
                        class="service-list-icon" />
                      {{
                        service.cuisines
                          .sort((a, b) => b.id - a.id)
                          .map((c) => c.name)
                          .join(", ")
                      }}
                    </p>

                    <p class="service-times">
                      <Icon
                        :path="mdiClock"
                        :size="16"
                        class="service-list-icon" />
                      {{ formatTime(service.startTime) }} -
                      {{ formatTime(service.endTime) }}
                    </p>
                    <p class="channels">
                      <Icon
                        :path="mdiShopping"
                        :size="16"
                        class="service-list-icon" />
                      {{ formatChannels(service.channels).join(", ") }}
                    </p>
                  </div>
                  <div
                    class="call-to-action"
                    v-if="
                      (service.channels.length == 1 &&
                        service.channels[0].name == 'WalkUp') ||
                      this.currentEvent.isOrderingAvailable === false
                    ">
                    View Menu
                  </div>
                  <div v-else class="call-to-action">Order Now</div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import store from "@/store";
  import { mapGetters } from "vuex";
  import EventWarnings from "@/components/EventWarnings";
  import Enumerable from "linq";
  import { LocalTime, LocalDate, ChronoUnit } from "@js-joda/core";
  import { formatChannels } from "@/helpers/formatChannel";
  import { replace } from "@tucktrucks/core";
  import { mdiFood, mdiClock, mdiShopping, mdiCellphoneLink } from "@mdi/js";
  import ImageCarousel from "@/components/Images/ImageCarousel.vue";
  import ListHeader from "@/components/ListHeader/VenueOutletListHeader.vue";
  import SiteListHeader from "@/components/ListHeader/VenueOutletListSiteHeader.vue";
  import Address from "@/components/ListHeader/Address.vue";
  export default {
    data() {
      return {
        venue: null,
        loading: false,
        vendorsLoading: false,
        events: [],
        currentEvent: null,
        eventNotFound: false,
        services: [],
        showWarning: false,
        warning: "",
        availableTimes: [],
        serviceIsOpen: [],
        servicesBySiteName: null,
        mdiFood,
        mdiClock,
        mdiShopping,
        mdiCellphoneLink,
        LocalTime,
        replace,
      };
    },

    props: {
      venueCode: {
        type: String,
      },
      mobileLayout: {
        type: Boolean,
      },
    },

    computed: {
      ...mapGetters({
        repoGetEventById: "repoEvents/getById",
        repoGetEventByIdWithQuery: "repoEvents/getByIdWithQuery",
        getVendorById: "repoVendors/getById",
      }),

      currentDate() {
        return this.currentEvent.date;
      },

      getCurrentEvent() {
        const todayServices =
          this.currentEvent?.services?.filter(
            (x) => x.startDate == this.currentDate
          ) ?? [];

        const todayServicesOpen = todayServices
          .filter(
            (service) =>
              service.status != "Finished" && service.status != "Cancelled"
          )
          .sort((a, b) => a.vendor.id > b.vendor.id);

        const todayServicesClosed = todayServices
          .filter(
            (service) =>
              service.status == "Finished" || service.status == "Cancelled"
          )
          .sort((a, b) => a.vendor.id > b.vendor.id);

        const sortedCurrentOpenService = todayServicesOpen
          .sort((a, b) => {
            if (a.outlet?.nameDisplay > b.outlet?.nameDisplay) {
              return 1;
            }
            if (a.outlet?.nameDisplay < b.outlet?.nameDisplay) {
              return -1;
            }
            if (a.startTime > b.startTime) {
              return 1;
            }
            if (a.startTime < b.startTime) {
              return -1;
            }
            return 0;
          })
          .concat(
            todayServicesClosed.sort((a, b) => {
              if (a.outlet?.nameDisplay > b.outlet?.nameDisplay) {
                return 1;
              }
              if (a.outlet?.nameDisplay < b.outlet?.nameDisplay) {
                return -1;
              }
              if (a.startTime > b.startTime) {
                return 1;
              }
              if (a.startTime < b.startTime) {
                return -1;
              }
              return 0;
            })
          );
        const eventBySite = {};

        sortedCurrentOpenService
          .map((s) => {
            return {
              siteName: s.pitch?.site?.name,
              ...s,
            };
          })
          .forEach((s) => {
            if (s.siteName === undefined) s.siteName = "undefined";
            if (!eventBySite[s.siteName]) eventBySite[s.siteName] = [];
            eventBySite[s.siteName].push(s);
          });

        return eventBySite;
      },
    },

    methods: {
      formatChannels,
      getVenueCdnUrls(thumbnails) {
        return thumbnails.map(
          ({ cdnUrl }) => `${process.env.VUE_APP_CDN}` + "/" + cdnUrl
        );
      },

      getVendorThumbnail(service) {
        return this.getVendorById(service.vendor.id).then((vendor) => {
          let thumbnailSrc = [];

          for (let thumbnail of vendor.thumbnails) {
            thumbnailSrc.push(
              `${process.env.VUE_APP_CDN}` + "/" + thumbnail.cdnUrl
            );
          }

          return thumbnailSrc;
        });
      },

      getThumbnail(thumbnails) {
        let thumbnailSrc = [];

        if (thumbnails?.service?.length > 0) {
          for (const thumbnail of thumbnails?.service) {
            thumbnailSrc.push(
              `${process.env.VUE_APP_CDN}` + "/" + thumbnail.cdnUrl
            );
          }
        } else if (thumbnails?.servicePrototype?.length > 0) {
          for (const thumbnail of thumbnails?.servicePrototype) {
            thumbnailSrc.push(
              `${process.env.VUE_APP_CDN}` + "/" + thumbnail.cdnUrl
            );
          }
        } else if (thumbnails?.outlets?.length > 0) {
          for (const thumbnail of thumbnails?.outlets) {
            thumbnailSrc.push(
              `${process.env.VUE_APP_CDN}` + "/" + thumbnail.cdnUrl
            );
          }
        } else if (thumbnails?.vendors?.length > 0) {
          for (const thumbnail of thumbnails?.vendors) {
            thumbnailSrc.push(
              `${process.env.VUE_APP_CDN}` + "/" + thumbnail.cdnUrl
            );
          }
        }

        // temp reduction to single array item

        if (thumbnailSrc.length == 0) {
          return [];
        }

        return [thumbnailSrc[0]];
      },
      getEvents() {
        return Enumerable.from(this.events)
          .orderBy((x) => x.date)
          .toArray();
      },

      getDistinctVendors() {
        return Enumerable.from(this.events)
          .selectMany((x) => x.services)
          .distinct((x) => x.vendor.id)
          .orderBy((x) => x.name)
          .toArray();
      },

      getDistinctDates() {
        return Enumerable.from(this.events)
          .select((x) => x.date)
          .distinct()
          .orderBy()
          .toArray();
      },

      selectEvent(event) {
        this.currentEvent = event;
        this.getCurrentEventServices(event.id);
      },

      async getCurrentEventServices(eventId) {
        const currentEventWithServices = await this.repoGetEventByIdWithQuery(
          eventId
        );

        for (const service of currentEventWithServices.services) {
          service.vendor.thumbnailSrc = await this.getVendorThumbnail(service);
        }
        this.currentEvent["services"] = currentEventWithServices.services;
        return currentEventWithServices.services;
      },
      getNextTimes(serviceId) {
        return store.state.apiPublic.client.endpoints.services
          .getNextTime(serviceId)
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }

            if (response.status == 204) {
              return null;
            }

            return Promise.reject("Failed to find next times.");
          })
          .catch(() => {
            // ignore
          });
      },
      routerPush(service) {
        if (
          service.channels.length == 1 &&
          service.channels[0].name == "WalkUp"
        ) {
          return;
        } else {
          this.$router.push("/service/" + service.id);
        }
      },
      async fetchVenue() {
        await store.state.apiPublic.client.endpoints.venues
          .getbyCode(this.venueCode)
          .then((response) => {
            this.venue = response.data.data;
          });
      },
      async fetchData() {
        this.$emit("cartHidden");

        const venueId = this.venue.id;

        const mode = this.$route.meta.mode;

        switch (mode) {
          default:
          case "upcoming":
            store.state.apiPublic.client.endpoints.venues
              .getUpcomingEvents(venueId)
              .then(async (response) => {
                if (response.status == 200) {
                  const events = response.data.data;

                  if (events.length > 0) {
                    this.currentEvent = Enumerable.from(events)
                      .orderBy((x) => x.date)
                      .toArray()[0];

                    // use current event Id to get the event services
                    await this.getCurrentEventServices(this.currentEvent.id);

                    await this.currentEvent.services?.forEach(
                      async (service) => {
                        const nowDate = LocalDate.now();
                        const serviceDate = LocalDate.parse(service.startDate);
                        const channels = service.channels.map(
                          (channel) => channel.name
                        );

                        if (!nowDate.isEqual(serviceDate)) {
                          this.availableTimes[service.id] = null;
                        } else {
                          // check if service is open
                          const startTime = LocalTime.parse(service.startTime);
                          const timeNow = LocalTime.now();
                          if (timeNow.isBefore(startTime)) {
                            this.availableTimes[service.id] = 0;
                            this.serviceIsOpen[service.id] = false;
                          } else {
                            if (
                              channels.includes("ClickCollect") ||
                              channels.includes("Delivery")
                            ) {
                              const nextString = await this.getNextTimes(
                                service.id
                              );
                              if (nextString != null) {
                                const next = LocalTime.parse(nextString);
                                let minutes = timeNow.until(
                                  next,
                                  ChronoUnit.MINUTES
                                );

                                // If the queue is short (< 5) then lock it off to 0 - the UI then reflects that as "no queue"
                                if (minutes < 5) {
                                  minutes = 0;
                                }

                                // Round it to the nearest 5 minutes.
                                minutes = Math.round(minutes / 5) * 5;

                                this.availableTimes[service.id] = minutes;
                                this.serviceIsOpen[service.id] = true;
                              } else this.availableTimes[service.id] = null;
                            }
                          }
                        }
                      }
                    );
                  }

                  this.events = events;
                  this.loading = false;
                } else {
                  window.log.error("Could not download upcoming events.");
                  this.warning = "Could not download upcoming events.";
                  this.showWarning = true;
                }
              });
            break;
        }
      },
    },
    async beforeMount() {
      this.loading = true;
      await this.fetchVenue();
      await this.fetchData();
    },

    components: {
      EventWarnings,
      ImageCarousel,
      ListHeader,
      SiteListHeader,
      Address,
    },
  };
</script>

<style lang="scss" scoped>
  .venue-site {
    > h1 {
      padding: 1rem 8%;
    }

    header {
      position: sticky;
      top: 3.5rem;
    }
  }
  section {
    &.event-list {
      margin-top: 0;
      padding: 0 !important;
    }
  }

  .event {
    display: block;
    position: relative;
    width: 100%;
    padding: 0 3%;

    .tabletLayout & {
      padding: 0 5% 2rem;
    }

    h2 {
      @include outlet-list-title;
    }
  }

  .service-list {
    margin: 0;
    padding: 0.15rem 0 0 0;
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);

    &_link {
      height: 100%;
    }
  }

  @media only screen and (max-width: 639px) {
    .service-list {
      gap: 1.5rem;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media only screen and (min-width: 640px) {
    .service-list {
      gap: 1.5rem;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (min-width: 961px) {
    .service-list {
      gap: 2rem;
      grid-template-columns: repeat(3, 1fr);

      .cart-view & {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media only screen and (min-width: 1920px) {
    .service-list {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .service-list_item {
    width: 100%;
    cursor: pointer;
    height: initial !important;
    padding: 0 !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.closed {
      pointer-events: none;
      user-select: none;
      position: relative;
    }

    .inactive {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      background: rgba(123, 123, 123, 0.5);
      backdrop-filter: blur(4px);
      h2 {
        color: #fff;
      }
    }

    a {
      text-decoration: none;
      color: $col_black;
      display: flex;
      height: 100%;
      flex-direction: column;
      position: relative;
      padding-top: calc(56.25% + 30px);
      margin-top: calc(-56.25% - 30px);

      .vendor-name {
        font-size: 1.3rem;
        margin: 0 1.5rem 0.5rem 0;
        line-height: 1.6rem;
        text-align: left;
      }

      .service-name {
        position: relative;
        top: -0.333rem;
      }

      .service-times {
        margin-bottom: 0;
      }

      .call-to-action {
        display: block;
        padding: 1rem;
        font-weight: 500;
        text-align: center;
        color: $col_beta-darker;
        border-top: 3px solid $col_beta-darker;
        height: 60px;

        &.disabled {
          background: $col_disabled_gray;
          pointer-events: none;
          color: #9f9f9f;
          border-top: 3px solid #ccc;
        }
      }
      .material-icons-outlined {
        font-size: 18px;
        margin-right: 0.5rem;
      }
    }

    &.carousel a.service-list_link {
      padding-top: 0;
      margin-top: 0;
    }

    a:hover {
      .call-to-action {
        background: $col_beta-darker;
        color: #fff;
      }

      .call-to-action.disabled {
        background: $col_disabled_gray;
        cursor: default;
      }
    }
  }

  .service-list-icon {
    margin-right: 0.25rem;
  }

  .service-times {
    display: flex;
    align-items: center;
  }

  .service-times-icon {
    margin-right: 0.5rem;
    color: $col_alpha;
  }

  .image-container_outer {
    display: block;
    position: relative;
    overflow: hidden;
    padding-top: calc(56.25% + 30px);
    margin-bottom: -30px;
  }

  .image-container_inner {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;

    &.empty {
      .carousel {
        min-height: calc(100% - 30px);
        background-color: $col_offwhite;
      }
    }
  }

  .service-list_link_content_outer {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  .service-list_link_content_inner {
    padding: 1.3rem 1.25rem 2rem;
    flex-grow: 1;

    p {
      display: flex;
      font-weight: 300;
      font-size: 0.8rem;
      margin: 0;
      text-align: left;
    }
  }

  .busyness-indicator_outer {
    position: relative;
    height: 0;
    font-size: 0;
  }

  .busyness-indicator_inner {
    position: absolute;
    top: calc(56.25% - 35px);
    right: 0.5rem;
    left: auto;
    padding: 0.25rem 0.5rem 0.25rem 0.25rem;
    text-align: center;
    align-items: center;
    background: $col_alpha;
    color: $col_white;
    font-size: 0.8rem;
    gap: 0.25rem;
    border-radius: 5px;
    .mdi-icon {
      padding: 0.15rem;
    }
  }

  @media screen and (max-width: 639px) {
    .service-list_link_content_inner {
      padding: 1rem;
    }
  }

  .time-range {
    z-index: 5;
    font-weight: 400;
    display: block;
    line-height: 1.1rem;
    font-size: 0.85rem;
    color: $col_white;
  }

  .event-list-header {
    padding: 0 1rem;
    margin: 2rem;
    border-left: 0.5em solid $col_alpha;
  }
</style>
