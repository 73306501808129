<template>
  <p
    @click="$emit('click')"
    :class="{ full: ifFullAddress }"
    class="operator-address">
    <Icon :path="mdiMapMarker" :size="14" />
    <span>
      {{ ifFullAddress ? formatAddress(address) : address.postalCode }}
      <span class="toggle" v-if="showToggle && ifFullAddress">Hide</span>
    </span>
  </p>
</template>

<script>
  import { mdiMapMarker } from "@mdi/js";
  import { formatAddress } from "@tucktrucks/platform-base-public";

  export default {
    data() {
      return {
        mdiMapMarker,
        formatAddress,
      };
    },

    props: {
      address: {
        type: Object,
      },
      classes: {
        type: String,
      },
      showToggle: { type: Boolean, default: false },
      ifFullAddress: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["click"],
  };
</script>

<style lang="scss" scoped>
  .operator-address {
    @include outlet-list-clickable-info;
  }
</style>
